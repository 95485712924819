/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

type UseSocialScripts = (
	bodyHtml: string | undefined,
	isVisibleObserver: boolean
) => (() => void) | undefined;

const useSocialScripts: UseSocialScripts = (bodyHtml, isVisibleObserver) => {
	const toIds:any = [];
	const socialScripts = () => {
		const scripts = [
			{
				keyword: "tiktok-embed",
				src: "https://www.tiktok.com/embed.js",
			},
			{
				keyword: "twitter-tweet",
				src: "https://platform.twitter.com/widgets.js",
			},
			{
				keyword: "fb-post",
				src: "https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v16.0",
			},
			{
				keyword: "instagram-media",
				src: "https://www.instagram.com/static/bundles/metro/EmbedSDK.js/33cd2c5d5d59.js",
				// src: '//www.instagram.com/embed.js',
			},
		];

		for (const { keyword, src } of scripts) {
			if (bodyHtml?.includes(keyword) && typeof document !== "undefined") {
				const script = document?.createElement("script");
				script.src = src;
				script.async = true;
				document?.body?.appendChild(script);
				if (keyword === "fb-post") {
					const to1 = setTimeout(() => {
						// @ts-ignore
						if (typeof FB !== "undefined") {
							// @ts-ignore
							FB?.XFBML?.parse();
						}
						// @ts-ignore
						if (typeof instgrm !== "undefined") {
							// @ts-ignore
							instgrm.Embeds.process();
						}
					}, 500);
					toIds.push(to1);
				}
				if (keyword === "instagram-media") {
					const to2 = setTimeout(() => {
						// @ts-ignore
						if (typeof instgrm !== "undefined") {
							// @ts-ignore
							instgrm.Embeds.process();
						}
					}, 500);
					toIds.push(to2);
				}
			}
		}
	};

	useEffect(() => {
		socialScripts();
		for(const to of toIds){
			clearTimeout(toIds);
		}
	}, [bodyHtml]);

	useEffect(() => {
		if (isVisibleObserver !== undefined) {
			socialScripts();
		}
	}, [isVisibleObserver]);

	return socialScripts;
};

export default useSocialScripts;
